// import React from "react"
// import kebabCase from "lodash/kebabCase"
// import { Link, graphql } from "gatsby"
// import Layout from "../../components/Layout"
// import { Container } from "react-bootstrap"
// import NotFound from '../404'

// const Tags = ({ data: { allMarkdownRemark: { group } } }) => {
//     return (
//         <Layout>
//             <div className="main-content-padding">
//                 <div className="app-general-seciton app-section-tags" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
//                     <Container>
//                         <h1>All Tags</h1>
//                         <ul>
//                             {group.map(tag => (
//                                 <li key={tag.fieldValue}>
//                                     <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
//                                         {tag.fieldValue} ({tag.totalCount})
//                                     </Link>
//                                 </li>
//                             ))}
//                         </ul>
//                     </Container>
//                 </div>
//             </div>
//         </Layout>
//     )
// }

// export default Tags

// export const pageQuery = graphql`
//     query TagsPage {
//         allMarkdownRemark {
//             group(field: frontmatter___tags) {
//                 fieldValue
//                 totalCount
//             }
//         }
//     }
// `


import React from "react"
import NotFound from '../404'

const Tags = () => {
    return (
        <NotFound />
    )
}

export default Tags